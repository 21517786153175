



















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { fakeData } from '@/data/fakeData.js'

import DuoCtas from '@/components/jobs/DuoCtas.vue'
import JobCta from '@/components/jobs/Cta.vue'
import JobDetails from '@/components/jobs/Details.vue'
import HeroJob from '@/components/jobs/Hero.vue'
import JobsSlider from '@/components/jobs/Slider.vue'
import Process from '@/components/jobs/Process.vue'

export default defineComponent({
  name: 'job-single',
  components: {
    DuoCtas,
    HeroJob,
    JobCta,
    JobDetails,
    JobsSlider,
    Process,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
      fakeData,
    }
  },
})
