










































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import Strech from '@/components/animation/Strech.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'job-details',
  components: {
    Strech,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
    jobTitle: {
      required: false,
      type: String,
    },
  },
  setup() {
    const contentRef = ref()
    const currentUrl = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (contentRef.value) {
          TL.add(contentRef.value.getTween(), 'start')
        }
      }
    }

    onMounted(() => {
      currentUrl.value = window.location.href
    })

    return {
      animateIn,
      contentRef,
      currentUrl,
    }
  },
})
