


































import { defineComponent, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'

export default defineComponent({
  name: 'hero-job',
  components: {
    LineSplitter,
    ZoomOutImg,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const headlineRef = ref()
    const titleRef = ref()
    const detailsRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (headlineRef.value) {
          TL.add(headlineRef.value.getTween(), 'start')
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start+=0.1')
        }

        if (detailsRef.value) {
          TL.add(detailsRef.value.getTween(), 'start+=0.2')
        }
      }
    }

    return {
      animateIn,
      headlineRef,
      titleRef,
      detailsRef,
    }
  },
})
